<template>
  <div class="modal-card">
    <header class="modal-card-head has-background-primary">
      <p class="modal-card-title has-text-white has-text-centered">
        Select an account
      </p>
    </header>

    <section class="modal-card-body">
      <b-message type="is-dark">
        <p>
          This email address is associated with {{ resellers.length }} different
          accounts - please select which you would like to access.
        </p>
      </b-message>

      <button
        v-for="reseller in resellers"
        :key="reseller.id"
        :disabled="processing"
        :class="{ 'is-loading': processing === reseller.id }"
        class="button is-medium is-stretched has-margin-bottom-50 is-outlined"
        @click="login(reseller.id)"
      >
        <span class="is-size-6">{{ reseller.name }}</span>
      </button>
    </section>
  </div>
</template>

<script>
export default {
  name: "LoginSelectResellerModal",
  props: {
    email: {
      type: String,
      required: true
    },
    password: {
      type: String,
      required: true
    },
    resellers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      processing: false
    };
  },
  methods: {
    login(resellerId) {
      this.processing = resellerId;
      this.$store
        .dispatch("auth/loginReseller", {
          email: this.email,
          password: this.password,
          resellerId: resellerId
        })
        .then(result => {
          this.$emit("close");
          this.processing = false;
          if (result && result.message) {
            this.$toast.open({
              message: result.message,
              position: "is-bottom",
              queue: false
            });
          }
        })
        .catch(error => {
          this.processing = false;
          if (error && error.message) {
            this.$snackbar.open({
              message: error.message,
              position: "is-bottom",
              type: "is-danger",
              duration: 10000,
              queue: false
            });
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";

.reseller {
  background-color: $white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  align-items: center;
  margin: 0 0 0 0;
  display: flex;
  color: $text;
  padding: 1em 1.5em;
  justify-content: flex-start;
  margin-top: 0.5em;
  border-radius: 0.3em;

  &:hover {
    cursor: pointer;
    background: $light;
    border-color: $primary;
    border-style: solid;
    border-width: 0 0 0 4px;
  }
}
</style>
